<template>
  <div class="vote-dialog" v-if="show">
    <van-overlay :show="show" :lock-scroll="false">
      <div class="section-wrapper">
        <div class="section-wrapper--flex">
          <div class="flex-title">
            <span>公告</span>
          </div>
          <div class="flex-content">
            <div v-if="loading" class="flex-content-loading">
              <van-loading type="spinner" size="40" />
            </div>
            <template v-else>
              <vue-pdf
                v-for="i in numPages"
                :key="i"
                :page="i"
                ref="pdf"
                :src="pdfURL"
              ></vue-pdf>
            </template>
          </div>
          <div class="flex-button">
            <div class="flex-button--check" @click="check">
              <span>确 定</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Overlay, Loading } from "vant";
import pdf from "vue-pdf";

export default {
  components: {
    vanOverlay: Overlay,
    vanLoading: Loading,
    "vue-pdf": pdf,
  },
  props: {
    // 显示弹窗控件
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
    // // 显示关闭按钮
    // showCloseBtn: {
    //   type: Boolean,
    //   default: false,
    // },
    //
  },
  data() {
    return {
      loading: false,
      id: `id_${new Date().getTime()}_${Math.random() * 1000}`,
      pdfURL: "",
      numPages: 0,
    };
  },
  watch: {
    url: {
      handler(val) {
        this.$nextTick(() => {
          this.getNumPages(val);
        });
      },
      immediate: true,
    },
  },
  methods: {
    getNumPages(url) {
      if (!url) {
        return;
      }
      this.loading = true;
      let loadingTask = pdf.createLoadingTask(url);
      loadingTask.promise
        .then((pdf) => {
          this.pdfURL = loadingTask;
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf加载失败");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 确认按钮
    check() {
      this.$emit("check");
    },
  },
};
</script>
<style lang="less" scoped>
.vote-dialog {
  font-family: PingFangSC-Regular, PingFang SC;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9;
  .section-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .section-wrapper--flex {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - 84px);
      height: calc(100% - 128px);
      box-sizing: border-box;
      padding: 62px 30px 68px;
      background: #ffffff;
      border-radius: 16px;
      .flex-title {
        text-align: center;
        color: #333333;
        font-size: 32px;
        line-height: 44px;
        font-weight: bold;
        padding-bottom: 38px;
      }
      .flex-content {
        box-sizing: border-box;
        flex: 1;
        padding: 0 0 30px;
        overflow-x: hidden;
        overflow-y: auto;
        .flex-content-loading {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      .flex-button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .flex-button--check {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 30px;
          font-weight: bold;
          width: 80%;
          height: 74px;
          background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
          border-radius: 39px;
          border: 8px solid #ecf7ff;
        }
      }
    }
  }
}
</style>
